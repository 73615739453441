.QuizList {
    display: flex;
    justify-content: center;
    padding-top: 100px;
    flex-grow: 1;
    width: 100%;
    background: linear-gradient(90deg, #fd8355 0%, #f0576c 37%, #f79cbd 100%);
}

.QuizList h1 {
    color: #fff;
}

.QuizList ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.QuizList ul li {
    margin-bottom: 10px;
}

.QuizList ul li a {
    text-decoration: none;
    color: #fff;
}

.QuizList ul li a:hover {
    color: #363d54;
}