.Input {
    margin-bottom: 15px;
}

.Input label {
    margin-bottom: 3px;
    padding: 0;
    display: block;
    font-weight: bold;
}

.Input input {
    display: block;
    box-sizing: border-box;
    border: 1px solid #bebebe;
    padding: 7px;
    margin: 0 0 5px;
    width: 100%;
    outline: 0;
    transition: all 300ms ease-in-out;
}

.Input span {
    color: #f01f30;
    font-size: 12px;
    font-weight: bold;
}

.Input.invalid label {
    color: #f01f30;
}