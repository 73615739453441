.AnswerItem {
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 5px 10px;
    margin-bottom: 5px;
    cursor: pointer;
}

.AnswerItem:hover {
    background: rgba(255, 255, 255, .2);
    transition: background .3s ease-in-out;
}

.AnswerItem.success {
    background: rgba(161, 240, 69, .7);
}

.AnswerItem.error {
    background: rgba(240, 87, 108, .7);
}