.MenuToggle {
    position: fixed;
    top: 40px;
    left: 40px;
    font-size: 20px;
    cursor: pointer;
    color: #fff;
    transition: opacity, left .22s ease-in;
    z-index: 100;
}

.MenuToggle:hover {
    opacity: .7;
}

.MenuToggle.open {
    left: 320px;
}