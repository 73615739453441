.Select {
    margin-bottom: 15px;
}

.Select label {
    margin: 0 0 3px 0;
    padding: 0;
    font-weight: bold;
    display: block;
}

.Select select {
    display: block;
    box-sizing: border-box;
    border: 1px solid #bebebe;
    margin: 0 0 5px;
    height: 29px;
    width: 100%;
    outline: none;
    transition: all 300ms ease-in-out;
}