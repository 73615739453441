.Layout {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.Layout main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}