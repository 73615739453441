.QuizCreator {
    display: flex;
    justify-content: center;
    padding-top: 50px;
    flex-grow: 1;
    width: 100%;
    background: linear-gradient(270deg, #f0613c 0%, #f0783c 100%);
}

.QuizCreator > div {
    width: 100%;
    max-width: 600px;
    padding: 0 20px;
}

.QuizCreator h1 {
    text-align: center;
}

.QuizCreator form {
    background: #eee;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, .5);
}