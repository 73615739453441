.Quiz {
    display: flex;
    justify-content: center;
    padding-top: 100px;
    flex-grow: 1;
    width: 100%;
    background: linear-gradient(90deg, #5041b2 0%, #7969e6 100%);
}

.Quiz h1 {
    color: #fff;
    margin-left: 10px;
}

.QuizWrapper {
    width: 600px;
}