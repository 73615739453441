.FinishedQuiz {
    padding: 20px;
    color: #fff;
    border: 2px solid #fff;
    border-radius: 5px;
    box-sizing: border-box;
    margin: 0 10px;
}

.FinishedQuiz ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.FinishedQuiz ul li i {
    margin-left: 10px;
}

.success {
    color: rgba(161, 240, 69, .7);
}

.error {
    color: rgba(240, 87, 108, .7);
}