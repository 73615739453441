.Drawer {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 80%;
    max-width: 300px;
    padding:40px 10px;
    box-sizing: border-box;
    background: #fff;
    transform: translateX(0px);
    transition: transform .22s ease-in;
    z-index: 90;
}

.Drawer.close {
    transform: translateX(-300px);
}

.Drawer ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.Drawer ul li {
    margin-bottom: 15px;
}

.Drawer ul li a {
    color: #363d54;
    font-size: 25px;
    text-decoration: none;
    background-color: #fff;
    position: relative;
    padding: 0 20px 10px 20px;
    transition: opacity .3s;
}

.Drawer ul li a:hover, .active {
    opacity: .7;
}